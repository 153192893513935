import { template as template_92b7842719234155bd1b493e43cd9638 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_92b7842719234155bd1b493e43cd9638(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
