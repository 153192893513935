import { template as template_3ee3c67d9d6d4033b7283ec044ca6fdf } from "@ember/template-compiler";
const FKLabel = template_3ee3c67d9d6d4033b7283ec044ca6fdf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
